<template>
	<div id="app">
		<hed @changeLang="changeLanguage" />
		<boot :language="language" />
		<disclaimer :language="language" />
		<foot :language="language" />
	</div>
</template>

<script>
import boot from './components/boot.vue'
import hed from './components/hed.vue'
import foot from './components/foot.vue'
import disclaimer from './components/disclaimer.vue'
export default {
	name: 'App',
	components: { hed, disclaimer, foot, boot },
	data() {
		return {
			language: 'dk'
		}
	},
	methods: {
		changeLanguage(l) {
			this.language = l
		}
	}
}
</script>

<style lang="sass">
@import "~marx-css/css/marx.css"
@import "@/globals.sass"
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap');

*
	margin: 0

html
	scroll-behavior: smooth

body
	overflow-x: hidden
	margin: 0
	padding: 0
	hyphens: auto
	font-family: $family
	background-color: $text

section
	padding: 2em
	background-color: $blau
	margin-bottom: 0


ul,ol
	list-style: none
	margin: 0
	padding: 0
	li
		margin: 0
		padding: 0

fieldset
	padding: 0
	border-top: 1px solid $blau

a
	cursor: pointer
	text-decoration: none
	color: $blau
	&:visited, &:focus
		cursor: pointer
		text-decoration: none
		color: $blau
	&:hover
		cursor: pointer
		text-decoration: none
		color: white
		background-color: $blau
		span
			color: white

p
	font-size: 14px
	line-height: 150%

input,
input.el-input__inner
	font-size: 16px
	font-family: $family
</style>
