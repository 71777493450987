<template>
	<header>
		<div class="dark-bar">
			<div class="top_adress">
				Club Maritim 09 GmbH, Graf-Zeppelin-Str. 18, D-24941 Flensburg
			</div>
			<div class="lang_select">
				<a v-if="language == 'de'" @click="setLanguage('dk')">
					<img class="flag" :src="require(`@/assets/dk.svg`)" />
					dansk</a
				>
				<a v-if="language == 'dk'" @click="setLanguage('de')">
					<img class="flag" :src="require(`@/assets/de.svg`)" />
					deutsch</a
				>
			</div>
		</div>

		<div class="grey-bar">
			<a href="tel:+4904610011170">+49 (0) 461 – 900 111 70</a>
			<a href="mailto:info@club-maritim09.de">info@club-maritim09.de</a>
		</div>

		<div>
			<p>
				<img
					:src="require(`@/assets/CM09_logo.png`)"
					alt="clubmaritim09"
				/>
			</p>
			<div>
				<h1>{{ $t('allgemein.intro') }}</h1>
			</div>
			<p>
				{{ $t('allgemein.kooperation') }}<br /><br />
				<svg viewBox="0 0 149 149" xmlns="http://www.w3.org/2000/svg">
					<g fill="none" fill-rule="evenodd">
						<path
							fill="rgb(39, 34, 100)"
							d="M75 19.846L89.655 75 75 130.154 61.7 75z"
						/>
						<path
							fill="rgb(39, 34, 100)"
							d="M102 102L67.613 81.387 47 47l33.43 21.57z"
						/>
						<path
							fill="rgb(39, 34, 100)"
							d="M47 102l20.613-34.387L102 47 80.43 80.43z"
						/>
						<path
							fill="rgb(39, 34, 100)"
							d="M130.154 75L75 89.394 19.846 75 75 61.703z"
						/>
						<circle
							stroke="rgb(39, 34, 100)"
							stroke-width="5"
							stroke-linecap="square"
							cx="74.5"
							cy="74.5"
							r="71.5"
						/>
						<circle
							stroke="#FFF"
							stroke-width="4"
							stroke-linecap="square"
							cx="75"
							cy="75"
							r="8"
						/>
					</g></svg
				><br />
				<strong>ratemyboat</strong>
			</p>
		</div>
	</header>
</template>

<script>
export default {
	data() {
		return {
			language: 'dk'
		}
	},
	methods: {
		setLanguage(l) {
			this.language = l
			this.$i18n.locale = this.language
			this.$emit('changeLang', this.language)
		}
	}
}
</script>

<style lang="sass" scoped>
@import "@/globals.sass"

header
	background-color: white
	font-size: 16px
	margin: 0
	& > div
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		&.dark-bar
			padding: 5px
			background-color: $text
			color: white
			a
				display: block
				width: 100px
				&:hover
					background-color: transparent
				img
					display: inline
					height: 16px
		&.grey-bar
			background-color: #f8f8f8
			color: $text
			margin-bottom: $abstand
			padding: 5px
			a
				padding: 0 0 0 20px
				background-size: 16px
				background-position: 0 4px
				background-repeat: no-repeat
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cpath fill='rgba(177, 206, 217, 0.4)' d='M28.373 13.546a2 2 0 00-2.746 2.908L42.087 32l-16.46 15.546a2 2 0 002.746 2.908l18-17a2.001 2.001 0 000-2.908l-18-17z'/%3E%3Cpath fill='rgba(177, 206, 217, 0.4)' d='M32 0C23.453 0 15.417 3.329 9.374 9.373 3.329 15.417 0 23.453 0 32s3.33 16.583 9.374 22.626C15.417 60.671 23.453 64 32 64s16.583-3.329 22.626-9.373C60.671 48.583 64 40.547 64 32s-3.33-16.583-9.374-22.626C48.583 3.329 40.547 0 32 0zm19.797 51.798C46.509 57.087 39.479 60 32 60s-14.509-2.913-19.798-8.202C6.913 46.51 4 39.479 4 32s2.913-14.51 8.203-19.798C17.491 6.913 24.521 4 32 4s14.509 2.913 19.798 8.202C57.087 17.49 60 24.521 60 32s-2.913 14.51-8.203 19.798z'/%3E%3C/svg%3E");
		p
			img
				height: 130px
			svg
				height: 60px
			strong
				color: rgb(39, 34, 100)
			&.motto
				margin: $abstand/2 0 0 0
				text-align: center
		h1
			text-align: center
			font-size: 24px
			color: $blau
		p
			text-align: center


@media (min-width: 600px)
	header
		& > div
			flex-direction: row
			justify-content: space-around
</style>
