<template>
	<section>
		<div v-if="form_sent" class="gesendet">
			<h2>{{ $i18n.t('allgemein.danke_hed') }}</h2>
			<p>{{ $i18n.t('allgemein.danke_body') }}</p>
		</div>
		<form v-else>
			<fieldset id="allgemein">
				<ul class="form">
					<li class="non-flex">
						<bootsprop
							:label="$t('allgemein.mitgliedsnummer')"
							:required="true"
						>
							<input v-model="mitgliedsnr" type="text" />
						</bootsprop>
					</li>
					<li class="non-flex">
						<bootsprop label="Email" :required="true">
							<input v-model="email" type="text" />
						</bootsprop>
					</li>
					<li class="non-flex">
						<h4>{{ $t('allgemein.bootstyp_suchen') }}</h4>
						<input
							ref="modell"
							v-model="boot.automodell"
							class="modell-input"
							:placeholder="$t('allgemein.mindestens_drei')"
							@input="fetchModelle"
						/>
						<div v-if="showAutoloadList">
							<ol class="modelle">
								<li v-for="(m, i) in modelle" :key="i">
									<a @click="selectBoot(m)"
										><span>{{ m.werft }}:</span>
										{{ m.name }}</a
									>
								</li>
							</ol>
						</div>
					</li>
					<li v-if="loading">
						<spinner />
					</li>
					<li>
						<bootsprop
							:label="$t('motor.bootstyp')"
							:required="true"
						>
							<input v-model="boot.modell" />
						</bootsprop>
						<bootsprop
							:label="$t('motor.baujahr')"
							:required="true"
						>
							<input v-model="boot.baujahr" />
						</bootsprop>
						<el-switch
							v-model="boot.typ"
							class="type-switch"
							:active-text="$t('allgemein.motorboot')"
							:inactive-text="$t('allgemein.segelboot')"
							active-color="rgb(177, 206, 217)"
							inactive-color="rgb(177, 206, 217)"
							active-value="MY"
							inactive-value="SY"
						/>
					</li>
					<li>
						<bootsprop :label="$t('motor.name')" :required="true">
							<input v-model="boot.name" />
						</bootsprop>
						<bootsprop
							:label="$t('motor.rumpfnummer')"
							:required="true"
						>
							<input v-model="boot.rumpfnummer" />
						</bootsprop>
					</li>
					<li>
						<bootsprop :label="$t('motor.beiboot_exists')">
							<el-select
								v-model="boot.beiboot_exists"
								style="width:15ch"
							>
								<el-option
									:label="$t('allgemein.nein')"
									:value="false"
								/>
								<el-option
									:label="$t('allgemein.ja')"
									:value="true"
								/>
							</el-select>
						</bootsprop>
						<bootsprop
							v-if="boot.beiboot_exists"
							:label="$t('motor.beiboot_typ')"
						>
							<input v-model="boot.beiboot_typ" />
						</bootsprop>
						<bootsprop
							v-if="boot.beiboot_exists"
							:label="$t('motor.beiboot_marke')"
						>
							<input v-model="boot.beiboot_marke" />
						</bootsprop>
						<bootsprop
							v-if="boot.beiboot_exists"
							:label="$t('motor.beiboot_groesse')"
						>
							<input v-model="boot.beiboot_groesse" />
						</bootsprop>
					</li>
				</ul>
			</fieldset>
			<fieldset id="boot">
				<ul class="form">
					<li>
						<bootsprop
							:label="$t('motor.motorhersteller')"
							:required="true"
						>
							<el-select v-model="boot.motorhersteller">
								<el-option
									v-for="(h, index) in motorhersteller"
									:key="index"
									:label="h"
									:value="h"
								/>
							</el-select>
						</bootsprop>
						<bootsprop
							:label="$t('motor.motortyp')"
							:required="true"
						>
							<el-select
								v-model="boot.motortyp"
								style="width:15ch"
							>
								<el-option
									:label="$t('motor.einbau_diesel')"
									:value="$t('motor.einbau_diesel')"
								/>
								<el-option
									:label="$t('motor.einbau_benzin')"
									:value="$t('motor.einbau_benzin')"
								/>
								<el-option
									:label="$t('motor.außenbordmotor')"
									:value="$t('motor.außenbordmotor')"
								/>
								<el-option
									:label="$t('motor.elektromotor')"
									:value="$t('motor.elektromotor')"
								/>
							</el-select>
						</bootsprop>
						<bootsprop
							:label="$t('motor.motorleistung_ps')"
							:required="true"
						>
							<input v-model="boot.motorleistung_ps" />
						</bootsprop>
						<bootsprop
							:label="$t('motor.motornummer')"
							:required="true"
						>
							<input v-model="boot.motornummer" />
						</bootsprop>
					</li>
					<li>
						<bootsprop
							:label="$t('motor.motorbaujahr')"
							:required="true"
						>
							<input v-model="boot.motorbaujahr" />
						</bootsprop>
						<bootsprop :label="$t('motor.betriebsstunden')">
							<input v-model="boot.betriebsstunden" />
						</bootsprop>
						<bootsprop :label="$t('motor.kabinen')">
							<el-select v-model="boot.kabinen">
								<el-option
									v-for="n in 5"
									:key="n"
									:label="n"
									:value="n"
								/>
							</el-select>
						</bootsprop>
						<bootsprop :label="$t('motor.nutzung')">
							<el-select v-model="boot.nutzung">
								<el-option
									:label="$t('motor.keine_angabe')"
									:value="$t('motor.keine_angabe')"
								/>
								<el-option
									:label="$t('motor.privat')"
									:value="$t('motor.privat')"
								/>
								<el-option
									:label="$t('motor.charter')"
									:value="$t('motor.charter')"
								/>
							</el-select>
						</bootsprop>
					</li>
					<li>
						<bootsprop
							:label="$t('motor.revier_alt')"
							:required="true"
						>
							<el-select v-model="boot.revier_alt">
								<el-option
									:label="$t('motor.nordsee')"
									:value="$t('motor.nordsee')"
								/>
								<el-option
									:label="$t('motor.ostsee')"
									:value="$t('motor.ostsee')"
								/>
								<el-option
									:label="$t('motor.frankreich')"
									:value="$t('motor.frankreich')"
								/>
								<el-option
									:label="$t('motor.mittelmeer')"
									:value="$t('motor.mittelmeer')"
								/>
								<el-option
									:label="$t('motor.karibik')"
									:value="$t('motor.karibik')"
								/>
								<el-option
									:label="$t('motor.binnen')"
									:value="$t('motor.binnen')"
								/>
								<el-option
									:label="$t('motor.sonstiges')"
									:value="$t('motor.sonstiges')"
								/>
							</el-select>
						</bootsprop>
						<bootsprop
							:label="$t('motor.revier_neu')"
							:required="true"
						>
							<el-select v-model="boot.revier_neu">
								<el-option
									:label="$t('motor.nordsee')"
									:value="$t('motor.nordsee')"
								/>
								<el-option
									:label="$t('motor.ostsee')"
									:value="$t('motor.ostsee')"
								/>
								<el-option
									:label="$t('motor.frankreich')"
									:value="$t('motor.frankreich')"
								/>
								<el-option
									:label="$t('motor.mittelmeer')"
									:value="$t('motor.mittelmeer')"
								/>
								<el-option
									:label="$t('motor.karibik')"
									:value="$t('motor.karibik')"
								/>
								<el-option
									:label="$t('motor.binnen')"
									:value="$t('motor.binnen')"
								/>
								<el-option
									:label="$t('motor.sonstiges')"
									:value="$t('motor.sonstiges')"
								/>
							</el-select>
						</bootsprop>
						<bootsprop :label="$t('motor.lager')" :required="true">
							<input v-model="boot.lager" />
						</bootsprop>
						<bootsprop
							:label="$t('motor.anzahl_voreigner')"
							:required="true"
						>
							<el-select v-model="boot.anzahl_voreigner">
								<el-option
									:label="$t('motor.keine')"
									:value="$t('motor.keine')"
								/>
								<el-option :label="1" :value="1" />
								<el-option :label="2" :value="2" />
								<el-option :label="3" :value="3" />
								<el-option :label="4" :value="4" />
								<el-option :label="5" :value="5" />
								<el-option
									:label="$t('motor.mehr_5')"
									:value="$t('motor.mehr_5')"
								/>
							</el-select>
						</bootsprop>
					</li>
					<li>
						<bootsprop :label="$t('motor.trailer')">
							<el-select
								v-model="boot.trailer"
								style="width:15ch"
							>
								<el-option
									:label="$t('allgemein.nein')"
									:value="false"
								/>
								<el-option
									:label="$t('allgemein.ja')"
									:value="true"
								/>
							</el-select>
						</bootsprop>
						<bootsprop
							v-if="boot.trailer"
							:label="$t('motor.trailer_marke')"
						>
							<input v-model="boot.trailer_marke" />
						</bootsprop>
						<bootsprop
							v-if="boot.trailer"
							:label="$t('motor.trailer_kennz')"
						>
							<input v-model="boot.trailer_kennz" />
						</bootsprop>
						<bootsprop
							v-if="boot.trailer"
							:label="$t('motor.trailer_gewicht')"
						>
							<input v-model="boot.trailer_gewicht" />
						</bootsprop>
					</li>
					<li><hr /></li>
					<li>
						<bootsprop
							:label="$t('motor.zustand_rumpf')"
							:required="true"
						>
							<el-select v-model="boot.zustand_rumpf">
								<el-option
									v-for="(h, index) in zustand"
									:key="index"
									:label="h"
									:value="h"
								/>
							</el-select>
						</bootsprop>
						<bootsprop
							:label="$t('motor.zustand_interieur')"
							:required="true"
						>
							<el-select v-model="boot.zustand_interieur">
								<el-option
									v-for="(h, index) in zustand"
									:key="index"
									:label="h"
									:value="h"
								/>
							</el-select>
						</bootsprop>
						<bootsprop
							:label="$t('motor.zustand_motor')"
							:required="true"
						>
							<el-select v-model="boot.zustand_motor">
								<el-option
									v-for="(h, index) in zustand"
									:key="index"
									:label="h"
									:value="h"
								/>
							</el-select>
						</bootsprop>
						<bootsprop
							:label="$t('segel.zustand_st_gut')"
							:required="true"
							v-if="boot.typ == 'SY'"
						>
							<el-select
								v-model="boot.zustand_st_gut"
								style="width:15ch"
							>
								<el-option
									:label="$t('allgemein.nein')"
									:value="false"
								/>
								<el-option
									:label="$t('allgemein.ja')"
									:value="true"
								/>
							</el-select>
						</bootsprop>
					</li>
					<li><hr /></li>
					<li class="non-flex">
						<h4>
							{{ $t('motor.wertverbesserung') }}
							<span v-if="boot.typ == 'MY'">{{
								$t('allgemein.MY')
							}}</span>
							<span v-if="boot.typ == 'SY'">{{
								$t('allgemein.SY')
							}}</span>
						</h4>
					</li>
					<checkprop
						:bprop="{
							label: $t('motor.osmoseschutz'),
							name: 'osmoseschutz',
							value: boot.osmoseschutz,
							alter: boot.osmoseschutz_alter,
							mit_rechnung: true,
							rechnung: boot.osmoseschutz_rechnung
						}"
						@changed="handlePropChange"
					/>
					<checkprop
						:bprop="{
							label: $t('motor.motor_neu'),
							name: 'motor_neu',
							value: boot.motor_neu,
							alter: boot.motor_neu_alter,
							mit_rechnung: true,
							rechnung: boot.motor_neu_rechnung
						}"
						@changed="handlePropChange"
					/>
					<checkprop
						:bprop="{
							label: $t('motor.teakdeck_neu'),
							name: 'teakdeck_neu',
							value: boot.teakdeck_neu,
							alter: boot.teakdeck_neu_alter,
							mit_rechnung: true,
							rechnung: boot.teakdeck_neu_rechnung
						}"
						@changed="handlePropChange"
					/>
				</ul>
			</fieldset>
			<fieldset id="sonder">
				<ul class="form">
					<li class="non-flex">
						<h4>
							{{ $t('motor.sonderausstattung') }}
							<span v-if="boot.typ == 'MY'">{{
								$t('allgemein.MY')
							}}</span>
							<span v-if="boot.typ == 'SY'">{{
								$t('allgemein.SY')
							}}</span>
						</h4>
					</li>
					<li class="non-flex">
						<p>
							<strong>{{ $t('motor.hinweis') }}</strong>
						</p>
						<p>{{ $t('motor.hinweis_text') }}</p>
						<ol class="beispiele" v-if="boot.typ == 'MY'">
							<li>{{ $t('motor.steuerkompass') }}</li>
							<li>{{ $t('motor.kuehleinrichtung') }}</li>
							<li>{{ $t('motor.herd') }}</li>
							<li>{{ $t('motor.tanks') }}</li>
							<li>{{ $t('motor.polster') }}</li>
							<li>{{ $t('motor.beleuchtung') }}</li>
						</ol>
						<ol class="beispiele" v-if="boot.typ == 'SY'">
							<li>{{ $t('segel.sumlog') }}</li>
							<li>{{ $t('segel.echolot') }}</li>
							<li>{{ $t('segel.steuerkompass') }}</li>
							<li>{{ $t('segel.standardbesegelung') }}</li>
							<li>{{ $t('segel.kuehleinrichtung') }}</li>
							<li>{{ $t('segel.herd') }}</li>
							<li>{{ $t('segel.tanks') }}</li>
							<li>{{ $t('segel.polster') }}</li>
							<li>{{ $t('segel.beleuchtung') }}</li>
							<li>{{ $t('segel.antifouling') }}</li>
						</ol>
						<p>{{ $t('motor.zubehoer_text') }}</p>
						<p>
							<strong>{{ $t('motor.zubehoer_text2') }}</strong>
						</p>
					</li>
					<li>
						<bootsprop :label="$t('motor.motorisierung')">
							<el-select v-model="boot.motorisierung">
								<el-option
									:label="$t('motor.motorisierung_standard')"
									:value="$t('motor.motorisierung_standard')"
								/>
								<el-option
									:label="$t('motor.motorisierung_mehr')"
									:value="$t('motor.motorisierung_mehr')"
								/>
								<el-option
									:label="$t('motor.motorisierung_aussen')"
									:value="$t('motor.motorisierung_aussen')"
								/>
							</el-select>
						</bootsprop>
						<bootsprop :label="$t('motor.anzahl_motoren')">
							<el-select v-model="boot.anzahl_motoren">
								<el-option
									v-for="(h, index) in 4"
									:key="index"
									:label="h"
									:value="h"
								/>
							</el-select>
						</bootsprop>
						<bootsprop :label="$t('motor.leistung_kw')">
							<input v-model="boot.leistung_kw" />
						</bootsprop>
						<bootsprop :label="$t('motor.alter')">
							<el-select v-model="boot.motorisierung_alter">
								<el-option
									v-for="(h, index) in alterOptions"
									:key="index"
									:label="`${h} ${$t('motor.jahre')}`"
									:value="h"
								/>
							</el-select>
						</bootsprop>
					</li>
					<template v-if="boot.typ == 'MY'">
						<checkprop
							:bprop="{
								label: $t('motor.dph'),
								name: 'dph',
								value: boot.dph,
								alter: boot.dph_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.sonderfarbe'),
								name: 'sonderfarbe',
								value: boot.sonderfarbe,
								alter: boot.sonderfarbe_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.bugstrahlruder'),
								name: 'bugstrahlruder',
								value: boot.bugstrahlruder,
								alter: boot.bugstrahlruder_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.anker'),
								name: 'anker',
								value: boot.anker,
								alter: boot.anker_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.ankerwinsch'),
								name: 'ankerwinsch',
								value: boot.ankerwinsch,
								alter: boot.ankerwinsch_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.teak_cockpit'),
								name: 'teak_cockpit',
								value: boot.teak_cockpit,
								alter: boot.teak_cockpit_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.teak_laufdeck'),
								name: 'teak_laufdeck',
								value: boot.teak_laufdeck,
								alter: boot.teak_laufdeck_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.flexiTeek_cockpit'),
								name: 'flexiTeek_cockpit',
								value: boot.flexiTeek_cockpit,
								alter: boot.flexiTeek_cockpit_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.flexiTeek_laufdeck'),
								name: 'flexiTeek_laufdeck',
								value: boot.flexiTeek_laufdeck,
								alter: boot.flexiTeek_laufdeck_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.kork'),
								name: 'kork',
								value: boot.kork,
								alter: boot.kork_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.sonnenpolster'),
								name: 'sonnenpolster',
								value: boot.sonnenpolster,
								alter: boot.sonnenpolster_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.bimini'),
								name: 'bimini',
								value: boot.bimini,
								alter: boot.bimini_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.camperverdeck'),
								name: 'camperverdeck',
								value: boot.camperverdeck,
								alter: boot.camperverdeck_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.hafenpersenning'),
								name: 'hafenpersenning',
								value: boot.hafenpersenning,
								alter: boot.hafenpersenning_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.cockpittisch_Sitzgruppe'),
								name: 'cockpittisch_Sitzgruppe',
								value: boot.cockpittisch_Sitzgruppe,
								alter: boot.cockpittisch_Sitzgruppe_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.cockpittisch'),
								name: 'cockpittisch',
								value: boot.cockpittisch,
								alter: boot.cockpittisch_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.cockpitkuehlschrank'),
								name: 'cockpitkuehlschrank',
								value: boot.cockpitkuehlschrank,
								alter: boot.cockpitkuehlschrank_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.seetoilette'),
								name: 'seetoilette',
								value: boot.seetoilette,
								alter: boot.seetoilette_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.warmluftheizung'),
								name: 'warmluftheizung',
								value: boot.warmluftheizung,
								alter: boot.warmluftheizung_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.klimaanlage'),
								name: 'klimaanlage',
								value: boot.klimaanlage,
								alter: boot.klimaanlage_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.dieselgenerator'),
								name: 'dieselgenerator',
								value: boot.dieselgenerator,
								alter: boot.dieselgenerator_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.landstromanschluss'),
								name: 'landstromanschluss',
								value: boot.landstromanschluss,
								alter: boot.landstromanschluss_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.wechselrichter'),
								name: 'wechselrichter',
								value: boot.wechselrichter,
								alter: boot.wechselrichter_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.audioanlage'),
								name: 'audioanlage',
								value: boot.audioanlage,
								alter: boot.audioanlage_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.sprechfunk'),
								name: 'sprechfunk',
								value: boot.sprechfunk,
								alter: boot.sprechfunk_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.kartenplotter'),
								name: 'kartenplotter',
								value: boot.kartenplotter,
								alter: boot.kartenplotter_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.autopilot'),
								name: 'autopilot',
								value: boot.autopilot,
								alter: boot.autopilot_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.radar'),
								name: 'radar',
								value: boot.radar,
								alter: boot.radar_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.echolot'),
								name: 'echolot',
								value: boot.echolot,
								alter: boot.echolot_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.sumlog'),
								name: 'sumlog',
								value: boot.sumlog,
								alter: boot.sumlog_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('motor.ais'),
								name: 'ais',
								value: boot.ais,
								alter: boot.ais_alter
							}"
							@changed="handlePropChange"
						/>
					</template>
					<!-- SY -->
					<template v-if="boot.typ == 'SY'">
						<checkprop
							:bprop="{
								label: $t('segel.decksbelag'),
								name: 'decksbelag',
								value: boot.decksbelag,
								alter: boot.decksbelag_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.rodrigg'),
								name: 'rodrigg',
								value: boot.rodrigg,
								alter: boot.rodrigg_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.anker'),
								name: 'anker',
								value: boot.anker,
								alter: boot.anker_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.ankerwinsch'),
								name: 'ankerwinsch',
								value: boot.ankerwinsch,
								alter: boot.ankerwinsch_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.ladegeraet'),
								name: 'ladegeraet',
								value: boot.ladegeraet,
								alter: boot.ladegeraet_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.landstromanschluss'),
								name: 'landstromanschluss',
								value: boot.landstromanschluss,
								alter: boot.landstromanschluss_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.heizung'),
								name: 'heizung',
								value: boot.heizung,
								alter: boot.heizung_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.rettungsinsel'),
								name: 'rettungsinsel',
								value: boot.rettungsinsel,
								alter: boot.rettungsinsel_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.bugstrahlruder'),
								name: 'bugstrahlruder',
								value: boot.bugstrahlruder,
								alter: boot.bugstrahlruder_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.teak'),
								name: 'teak',
								value: boot.teak,
								alter: boot.teak_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.sprayhood'),
								name: 'sprayhood',
								value: boot.sprayhood,
								alter: boot.sprayhood_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.bimini'),
								name: 'bimini',
								value: boot.bimini,
								alter: boot.bimini_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.cockpitzelt'),
								name: 'cockpitzelt',
								value: boot.cockpitzelt,
								alter: boot.cockpitzelt_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.rollanlage'),
								name: 'rollanlage',
								value: boot.rollanlage,
								alter: boot.rollanlage_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.ukw'),
								name: 'ukw',
								value: boot.ukw,
								alter: boot.ukw_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.plotter'),
								name: 'plotter',
								value: boot.plotter,
								alter: boot.plotter_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.gps'),
								name: 'gps',
								value: boot.gps,
								alter: boot.gps_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.windmessanlage'),
								name: 'windmessanlage',
								value: boot.windmessanlage,
								alter: boot.windmessanlage_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.radar'),
								name: 'radar',
								value: boot.radar,
								alter: boot.radar_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.ais'),
								name: 'ais',
								value: boot.ais,
								alter: boot.ais_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.sonderkiel'),
								name: 'sonderkiel',
								value: boot.sonderkiel,
								alter: boot.sonderkiel_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.wc'),
								name: 'wc',
								value: boot.wc,
								alter: boot.wc_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.klimaanlage'),
								name: 'klimaanlage',
								value: boot.klimaanlage,
								alter: boot.klimaanlage_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.mikrowelle'),
								name: 'mikrowelle',
								value: boot.mikrowelle,
								alter: boot.mikrowelle_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.autopilot'),
								name: 'autopilot',
								value: boot.autopilot,
								alter: boot.autopilot_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.generator'),
								name: 'generator',
								value: boot.generator,
								alter: boot.generator_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.tv_anlage'),
								name: 'tv_anlage',
								value: boot.tv_anlage,
								alter: boot.tv_anlage_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.ukw_radio'),
								name: 'ukw_radio',
								value: boot.ukw_radio,
								alter: boot.ukw_radio_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.faltpropeller'),
								name: 'faltpropeller',
								value: boot.faltpropeller,
								alter: boot.faltpropeller_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.schotwinschen'),
								name: 'schotwinschen',
								value: boot.schotwinschen,
								alter: boot.schotwinschen_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.spinnaker'),
								name: 'spinnaker',
								value: boot.spinnaker,
								alter: boot.spinnaker_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.gennaker'),
								name: 'gennaker',
								value: boot.gennaker,
								alter: boot.gennaker_alter
							}"
							@changed="handlePropChange"
						/>
						<checkprop
							:bprop="{
								label: $t('segel.code'),
								name: 'code',
								value: boot.code,
								alter: boot.code_alter
							}"
							@changed="handlePropChange"
						/>
					</template>
					<checkprop
						:bprop="{
							label: $t('motor.badeplattform'),
							name: 'badeplattform',
							value: boot.badeplattform,
							alter: boot.badeplattform_alter
						}"
						@changed="handlePropChange"
					/>
					<checkprop
						:bprop="{
							label: $t('segel.badeplattform_hyd'),
							name: 'badeplattform_hyd',
							value: boot.badeplattform_hyd,
							alter: boot.badeplattform_hyd_alter
						}"
						@changed="handlePropChange"
					/>
				</ul>
			</fieldset>
			<fieldset id="bilder">
				<ul class="form">
					<li class="non-flex">
						<h4>{{ $t('allgemein.bilder') }} <sup>*</sup></h4>
						<label
							style="color:rgb(177, 206, 217)"
							v-html="$t('allgemein.upload_mod1')"
						/>
						<VueFileAgent
							ref="vueFileAgent"
							class="vfa-bckgrd"
							v-model="fileRecords"
							:upload-url="uploadUrl"
							@upload="onUpload($event)"
							@upload:error="onUploadError($event)"
							:multiple="true"
							:deletable="false"
							:meta="true"
							:accept="'image/*'"
							:max-size="'8MB'"
							:help-text="`${$t('allgemein.choose_file')}`"
							:error-text="{
								type:
									'Invalid file type. Only JPG images allowed',
								size: 'Files should not exceed 8MB in size'
							}"
						/>
					</li>
					<li v-if="okBilder">
						<bootsprop :label="$t('allgemein.upload_mod2')">
							<el-select v-model="boot.zertifikat_bild">
								<el-option
									v-for="(file, index) in okBilder"
									:key="index"
									:label="file"
									:value="file"
								/>
							</el-select>
						</bootsprop>
					</li>
					<li><hr /></li>
					<li class="non-flex">
						<bootsprop :label="$t('motor.anmerkungen')">
							<el-input
								v-model="boot.anmerkungen"
								type="textarea"
								:autosize="{ minRows: 2, maxRows: 10 }"
							/>
						</bootsprop>
					</li>
					<li class="bottone">
						<p v-if="disabled">
							{{ $t('allgemein.required') }}<br />
							{{ missingFieldsText }}
						</p>
						<el-button
							type="primary"
							round
							:disabled="disabled"
							@click="submit"
						>
							{{ $t('allgemein.speichern') }}
						</el-button>
					</li>
				</ul>
			</fieldset>
		</form>
	</section>
</template>

<script>
import axios from 'axios'
import spinner from '@/components/spinner.vue'
import bootsprop from '@/components/bootsprop.vue'
import checkprop from '@/components/checkprop.vue'

export default {
	components: { checkprop, bootsprop, spinner },
	data() {
		return {
			loading: false,
			showAutoloadList: false,
			form_sent: false,
			email: '',
			mitgliedsnr: '',
			dbid: '', // wenn aus dem autoinput ausgewählt, dann kommt hier die entsprechende id des bootes aus der DB rein
			fileRecords: [],
			uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
			modelle: [],
			alterOptions: ['< 5', '< 10', '< 15', '< 20', '>= 20'],
			boot: {
				anzahl_motoren: '',
				anzahl_voreigner: '',
				automodell: '',
				baujahr: '',
				beiboot_exists: false,
				betriebsstunden: '',
				kabinen: '',
				gfk: '',
				lager: '',
				leistung_kw: '',
				masseinheit: this.$i18n.t('motor.meter'),
				material_deck: 'GFK',
				material_rumpf: 'GFK',
				modell: '',
				motor_neu_alter: '',
				motor_neu_rechnung: '',
				motor_neu: '',
				motorbaujahr: '',
				motorhersteller: '',
				motorisierung_alter: '',
				motorisierung: '',
				motornummer: '',
				motorleistung_ps: '',
				motortyp: '',
				name: '',
				nutzung: '',
				osmoseschutz_alter: '',
				osmoseschutz_rechnung: '',
				osmoseschutz: '',
				revier_alt: '',
				revier_neu: '',
				rumpfbreite: '',
				rumpffarbe: '',
				rumpflaenge: '',
				rumpfnummer: '',
				teakdeck_neu_alter: '',
				teakdeck_neu_rechnung: '',
				teakdeck_neu: '',
				tiefgang: '',
				trailer_gewicht: '',
				trailer_kennz: '',
				trailer_marke: '',
				trailer: false,
				typ: 'MY',
				zustand_interieur: '',
				zustand_motor: '',
				zustand_rumpf: '',
				zustand_st_gut: 'nein',
				// Sonderzubehör
				ais_alter: '',
				ais: '',
				anker_alter: '',
				anker: '',
				ankerwinsch_alter: '',
				ankerwinsch: '',
				anmerkungen: '',
				audioanlage_alter: '',
				audioanlage: '',
				autopilot_alter: '',
				autopilot: '',
				badeplattform_alter: '',
				badeplattform: '',
				bimini_alter: '',
				bimini: '',
				bugstrahlruder_alter: '',
				bugstrahlruder: '',
				camperverdeck_alter: '',
				camperverdeck: '',
				cockpitkuehlschrank_alter: '',
				cockpitkuehlschrank: '',
				cockpitpolster_alter: '',
				cockpittisch_Sitzgruppe_alter: '',
				cockpittisch_Sitzgruppe: '',
				cockpittisch: '',
				dieselgenerator_alter: '',
				dieselgenerator: '',
				dph_alter: '',
				dph: '',
				echolot_alter: '',
				echolot: '',
				flexiTeek_cockpit_alter: '',
				flexiTeek_cockpit: '',
				flexiTeek_laufdeck_alter: '',
				flexiTeek_laufdeck: '',
				hafenpersenning_alter: '',
				hafenpersenning: '',
				kabinenanzahl: '',
				kartenplotter_alter: '',
				kartenplotter: '',
				klimaanlage_alter: '',
				klimaanlage: '',
				kork_alter: '',
				kork: '',
				landstromanschluss_alter: '',
				landstromanschluss: '',
				radar_alter: '',
				radar: '',
				seetoilette_alter: '',
				seetoilette: '',
				sonderfarbe_alter: '',
				sonderfarbe: '',
				sonnenpolster_alter: '',
				sonnenpolster: '',
				sprechfunk_alter: '',
				sprechfunk: '',
				sumlog_alter: '',
				sumlog: '',
				teak_cockpit_alter: '',
				teak_cockpit: '',
				teak_laufdeck_alter: '',
				teak_laufdeck: '',
				warmluftheizung_alter: '',
				warmluftheizung: '',
				wechselrichter_alter: '',
				wechselrichter: '',
				welcher_wert: '',
				welches_foto: '',
				zertifikat_bild: '',
				// segelboot
				antifouling: '',
				antifouling_alter: '',
				beleuchtung: '',
				beleuchtung_alter: '',
				herd: '',
				herd_alter: '',
				kuehleinrichtung: '',
				kuehleinrichtung_alter: '',
				polster: '',
				polster_alter: '',
				standardbesegelung: '',
				standardbesegelung_alter: '',
				steuerkompass: '',
				steuerkompass_alter: '',
				tanks: '',
				tanks_alter: '',
				spinnaker: '',
				spinnaker_alter: '',
				stehendes_gut: '',
				stehendes_gut_alter: '',
				schotwinchen: '',
				schotwinchen_alter: '',
				cockpitzelt: '',
				cockpitzelt_alter: '',
				code: '',
				code_alter: '',
				decksbelag: '',
				decksbelag_alter: '',
				faltpropeller: '',
				faltpropeller_alter: '',
				generator: '',
				generator_alter: '',
				gennaker: '',
				gennaker_alter: '',
				schotwinschen: '',
				schotwinschen_alter: '',
				gps: '',
				gps_alter: '',
				badeplattform_hyd: '',
				badeplattform_hyd_alter: '',
				heizung: '',
				heizung_alter: '',
				ladegeraet: '',
				ladegeraet_alter: '',
				mikrowelle: '',
				mikrowelle_alter: '',
				plotter: '',
				plotter_alter: '',
				rettungsinsel: '',
				rettungsinsel_alter: '',
				rodrigg: '',
				rodrigg_alter: '',
				rollanlage: '',
				rollanlage_alter: '',
				sonderkiel: '',
				sonderkiel_alter: '',
				sprayhood: '',
				sprayhood_alter: '',
				teak: '',
				teak_alter: '',
				tv_anlage: '',
				tv_anlage_alter: '',
				ukw_radio: '',
				ukw_radio_alter: '',
				ukw: '',
				ukw_alter: '',
				wc: '',
				wc_alter: '',
				windmessanlage: '',
				windmessanlage_alter: ''
			}
		}
	},
	computed: {
		okBilder() {
			if (!this.fileRecords) return []
			let arr = []
			for (let pic of this.fileRecords) {
				if (pic?.upload?.data?.upload_data?.client_name) {
					arr.push(pic?.upload?.data?.upload_data?.client_name)
				}
			}
			return arr
		},
		missingFields() {
			let remainingFields = []
			if (!this.email) remainingFields.push('Email')
			if (!this.mitgliedsnr)
				remainingFields.push(this.$i18n.t('allgemein.mitgliedsnummer'))
			if (this.okBilder.length < 1)
				remainingFields.push(this.$i18n.t('allgemein.mindBild'))
			let fields = [
				'name',
				'modell',
				'baujahr',
				// 'tiefgang',
				'motorhersteller',
				'motortyp',
				'motorbaujahr',
				'motorleistung_ps',
				'motornummer',
				'revier_alt',
				'revier_neu',
				'lager',
				'anzahl_voreigner',
				'rumpfnummer',
				// 'rumpflaenge',
				// 'rumpfbreite',
				// 'material_deck',
				// 'material_rumpf',
				'zustand_rumpf',
				'zustand_interieur',
				'zustand_motor'
			]
			if (this.boot.typ == 'SY') {
				fields.push('zustand_st_gut')
			}
			for (let f of fields) {
				if (!this.boot[f])
					remainingFields.push(this.$i18n.t('motor.' + f))
			}
			return remainingFields
		},
		missingFieldsText() {
			return this.missingFields.join(', ')
		},
		disabled() {
			if (this.missingFields.length > 0) return true
			return false
		},
		API() {
			return process.env.VUE_APP_API_URL
		},
		uploadUrl() {
			return `${this.API}Clubm09/up`
		},
		axios_config() {
			return {
				headers: {
					'Content-Type': 'application/json;charset=UTF-8',
					'Access-Control-Allow-Origin': '*'
				}
			}
		},
		zustand() {
			return [
				this.$i18n.t('motor.sehr_gut'),
				this.$i18n.t('motor.gut'),
				this.$i18n.t('motor.befriedigend'),
				this.$i18n.t('motor.ausreichend'),
				this.$i18n.t('motor.mangelhaft')
			]
		},
		motorisierung() {
			return [
				this.$i18n.t('motor.motorisierung_standard'),
				this.$i18n.t('motor.motorisierung_mehr'),
				this.$i18n.t('motor.motorisierung_aussen')
			]
		},
		schadenswerte() {
			return [
				this.$i18n.t('motor.keine'),
				'< 500€',
				'< 1.000€',
				'< 2.000€',
				'< 15.000€'
			]
		},
		motorhersteller() {
			return [
				this.$i18n.t('allgemein.ka'),
				'Beta-Marine',
				'Bukh',
				'Caterpillar',
				'Craftsman',
				'Cummins',
				'Evinrude',
				'Farymann',
				'Honda',
				'IVECO/Fiat',
				'Johnson',
				'Lombardini',
				'MAN',
				'Mariner',
				'Mercruiser',
				'Mercury',
				'MTU',
				'Nanni',
				'Perkins',
				'Ruggerini',
				'Selva',
				'Sole',
				'Suzuki',
				'Tohatsu',
				'Vetus',
				'Volkswagen',
				'Volvo-Penta',
				'Yamaha',
				'Yanmar'
			]
		}
	},
	mounted() {
		this.$refs.modell.focus()
	},
	methods: {
		submit() {
			this.loading = true
			axios
				.post(this.API + 'Clubm09/save', {
					mitgliedsnr: this.mitgliedsnr,
					email: this.email,
					dbid: this.dbid,
					boot: this.boot,
					bilder: this.okBilder
				})
				.then(() => {
					this.loading = false
					this.form_sent = true
					setTimeout(() => {
						window.scrollTo(0, 0)
					}, 500)
				})
				.catch(e => {
					console.log(e)
				})
		},
		fetchModelle() {
			if (this.boot.automodell.length > 2) {
				this.loading = true
				axios
					.post(this.API + 'Boote/get_Modelle', {
						string: this.boot.automodell
					})
					.then(r => {
						this.loading = false
						this.showAutoloadList = true
						this.modelle = r.data
					})
					.catch(e => {
						console.log(e)
					})
			}
		},
		selectBoot(item) {
			this.showAutoloadList = false
			this.boot.modell = item.werft + ' ' + item.name
			this.dbid = item.id
		},
		onUpload() {
			if (
				this.fileRecords.length == 1 &&
				this.fileRecords[0].upload.data
			) {
				this.boot.zertifikat_bild = this.fileRecords[0].upload.data.upload_data.client_name
			}
		},
		onUploadError() {
			// console.log('🚀 ~ onUploadError ~ e', e)
		},
		handlePropChange(prop) {
			this.boot[prop.name] = prop.value
			const propAlterName = `${prop.name}_alter`
			this.boot[propAlterName] = prop.alter
			const propRechnungName = `${prop.name}_rechnung`
			if (this.boot[propRechnungName])
				this.boot[propRechnungName] = prop.rechnung
		}
	}
}
</script>

<style lang="sass">
@import "@/globals.sass"

.gesendet
	background-color: white
	padding: 1rem 2rem
	max-width: 1000px
	margin: 0 auto

ul.form
	background-color: white
	padding: 1rem 2rem
	max-width: 1000px
	margin: 0 auto
	li
		margin-bottom: 1em
		display: flex
		flex-wrap: wrap
		justify-content: flex-start
		h4
			font-size: 20px
			color: $blau
		&.non-flex
			display: block
		&.check-line
			.el-checkbox__label
				font-size: 16px

@media (min-width: 940px)
	ul.form
		li
			flex-wrap: nowrap


ul li.bottone
    margin: 3em
    display: block
    text-align: center
    p
      color: #c60c30

ol.beispiele
	margin: 0 0 2em 2em
	list-style: disc
	li
		margin: 0
		padding: 0
		font-size: 14px

ol.modelle
	margin: 0 0 2em 0
	padding: 0
	display: block
	max-height: 200px
	overflow-y: scroll
	overflow-x: hidden
	li
		margin: 0
		a
			margin-bottom: 5px
			cursor: pointer
			span
				color: $grau

.type-switch
	transform: translateY(40px)

span.el-switch__label
	color: #ccc

h4 sup
	color: $rot
	font-size: 20px

.vfa-bckgrd
	background-size: contain
	background-position: center center
	background-repeat: no-repeat
	background-image: url(~@/assets/cm09_bilder_backgrd_2.jpg)
	.help-text
		color: $rot !important
		transform: translateY(40px)
	.file-preview
		svg
			transform: translateY(-30px)
.vue-file-agent,
.file-preview-new:before
	background: transparent !important


#loading
	text-align: center
	position: relative
	height: 80px
	&:after
		content: ''
		position: absolute
		top: 0
		left: calc(50% - 25px)
		display: block
		width: 50px
		height: 50px
		border: 3px solid $blaua
		border-radius: 50%
		border-top-color: $blau
		animation: spin 1s ease-in-out infinite

@keyframes spin
	to
		transform: rotate(360deg)
</style>
