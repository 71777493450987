<template>
	<div class="disclaimer">
		<div v-if="language == 'dk'">
			<h5>Disclaimer</h5>
			<ol>
				<li>
					Eftersom oplysningerne for værdiansættelsen bliver oplyst
					fra forbrugeren, kan certifikatet ikke indeholde oplysninger
					om fartøjets objektive tilstand, dets tilbehør og
					funktioner. Dette kan kun vurderes ved en fysisk vurdering.
				</li>
				<li>
					HIB Hanse GmbH er ikke ansvarlig for værdiansættelsen, der
					er fastlagt i certifikatet.
				</li>
				<li>
					Den af HIB Hanse GmbH fastlagte vurdering for det valgte
					fartøj, er baseret på brugerens egne oplysninger.
					Evalueringen er baseret ud fra Hanse-evalueringssystemer,
					der er beregnet middelværdier opstået efter udligning af
					regionale forskelle. De er kun gyldige for
					markedssituationen, der blev bestemt på tidspunktet for
					værdiansættelsen.
				</li>
				<li>
					Online værdiansættelse via www.ratemyboat.de er ikke mulig
					for både over 35 år, der kræver en fysisk vurdering for, at
					man kan vurdere fartøjets tilstand. Vurderingen foretages
					eksternt efter aftale. Kontakt HIB Hanse GmbH for et
					uforpligtende tilbud:
					<a href="mailto:sb@sportboot-gutachter.de"
						>sb@sportboot-gutachter.de</a
					>
				</li>
			</ol>
		</div>
		<div v-if="language == 'de'">
			<h5>Disclaimer</h5>
			<ol>
				<li>
					Da die Wertermittlung auf den Angaben des Nutzers basiert,
					kann das Zertifikat keine Aussage über den objektiven
					Zustand der Yacht, ihres Zubehörs und der Funktionalitäten
					enthalten. Dies kann nur durch eine sachverständige
					Begutachtung ermittelt werden.
				</li>
				<li>
					HIB Hanse GmbH haftet nicht für die Durchsetzbarkeit der in
					einer Sportbootbewertung ermittelten Werte.
				</li>
				<li>
					Die von HIB Hanse GmbH ermittelte Bewertung für das vom
					Nutzer gewählte Sportboot richtet sich nach den Angaben des
					Nutzers. Grundlage der Bewertung sind die jeweils aktuellen
					Hanse-Bewertungssysteme. Sie stellen Mittelwerte dar, die
					sich nach Ausgleich regionaler Unterschiede ergeben und nur
					Gültigkeit für die zum Zeitpunkt der Bewertung ermittelte
					Marktlage haben.
				</li>
				<li>
					Bei Booten ab einem Alter von 35 Jahren ist eine
					online-Bewertung über www.ratemyboat.de nicht möglich. Für
					diese Boote ist zur richtigen Erfassung des Zustands eine
					Besichtigung erforderlich. Diese Besichtigung kann im
					Remote-Verfahren oder durch einen Ortstermin erfolgen. Gerne
					unterbreiten wir hierfür ein individuelles Angebot:
					<a href="mailto:sb@sportboot-gutachter.de"
						>sb@sportboot-gutachter.de</a
					>
				</li>
			</ol>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		language: {
			type: String,
			default: 'de'
		}
	}
}
</script>

<style lang="sass" scoped>
@import "@/globals.sass"

.disclaimer
	background-color: #f8f8f8
	margin: 0
	padding: $abstand
	display: flex
	flex-wrap: nowrap
	flex-direction: row
	justify-content: center
	div
		max-width: 60ch
		h5
			margin-bottom: $abstand
			color: $blau
		ol
			list-style: numbers
			li
				margin-bottom: $abstand/2
				color: $grau
</style>
